import * as React from "react"
import { Routes } from "../data/routes"
import CTA from "../components/CTA"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Content = {
  pageName: "404: Not found",
  header: "Oops. It looks like that page is missing.",
  description: "Oops. It looks like that page is missing.",
  actions: [
    {
      link: Routes.home,
      text: "Go home",
    },
  ],
}

const NotFoundPage = () => (
  <Layout>
    <Seo title={Content.pageName} description={Content.description} />
    <CTA title={Content.header} actions={Content.actions} />
  </Layout>
)

export default NotFoundPage
